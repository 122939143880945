import { createSlice } from '@reduxjs/toolkit';

import { registerMember, memberRgistrations } from '../api/memberRegistration';

export const authSlice = createSlice({
  name: 'memberRegister',
  initialState: {
    loading: false,
    memberRegister: {},
    memberRegistrations: {},
    error: {},
  },
  reducers: {},
  extraReducers: {
    // getAllregistration
    [memberRgistrations.pending]: (state) => {
      state.loading = true;
    },
    [memberRgistrations.fulfilled]: (state, action) => {
      state.loading = false;
      state.allMemberRegistrations = action.payload;
    },
    [memberRgistrations.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // registration
    [registerMember.pending]: (state) => {
      state.loading = true;
    },
    [registerMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberRegister = action.payload;
    },
    [registerMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default authSlice.reducer;
