import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { CardMedia } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';

import { useScreenWidth } from 'src/hooks/useScreenWidth';

import OsperbLogo from 'src/assets/other/Osperb.png'
import Logo from 'src/assets/other/LogRgisterpage.svg';
import bannerBg from 'src/assets/other/bannerbg_register.svg';

import Iconify from 'src/components/iconify';

import RegisterForm from './register-form';

export default function EventRegistration() {
  const { screenWidth, isMobile } = useScreenWidth();
  const [isPadding, setIsPadding] = useState(false);

  return (
    <Stack
      sx={{
        backgroundColor: 'black',
        backgroundImage: `url(${bannerBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: isMobile ? 'auto' : '100%',
        minHeight: '100vh',
        color: 'white',
        position: 'relative',
      }}
    >
      <Container maxWidth="xl">
        <Stack
          mt={screenWidth < 1200 ? 5 : 15}
          mb={{ xs: 10, md: screenWidth > 900 && screenWidth < 1200 ? 5 : 0 }}
          sx={{ pl: 3 }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CardMedia
              component="img"
              src={Logo}
              alt="Logo"
              sx={{
                width: 40,
                height: 40,
              }}
            />
            <Typography variant="subtitle1" fontWeight={700}>
              MEA Engineering College
              {isMobile && <br />}
              <Typography component="span" variant="caption" fontSize={15} pl={isMobile ? 0 : 0.5}>
                Alumni UAE
              </Typography>
            </Typography>
          </Box>
          <Typography variant="caption" fontSize={11} py={4} sx={{ letterSpacing: 1 }}>
            DECEMBER 01, 2024 | SUNDAY
          </Typography>
          <Typography variant="h3">NostalMEA</Typography>
          <Typography fontWeight={700} fontSize={18}>
            {' '}
            International Alumni Meet
          </Typography>
          <Typography
            variant="caption"
            display="flex"
            alignItems="center"
            fontSize={13}
            pt={{ xs: 1.5, md: 3 }}
            gap={0.5}
            sx={{ letterSpacing: 0.5 }}
            width={{ xs: 210, md: 'unset' }}
          >
            <Iconify
              icon="mi:location"
              sx={{ width: { xs: 35, md: 15 }, height: { xs: 35, md: 15 } }}
            />
            Salama Community Hall, Dubai Airport Free Zone UAE
          </Typography>
        </Stack>
        {screenWidth >= 1200 && (
          <Stack
            position="absolute"
            right={
              // eslint-disable-next-line no-nested-ternary
              screenWidth < 1300 ? 20 : screenWidth < 1440 ? 40 : 150
            }
            top={50}
          >
            <RegisterForm setIsPadding={setIsPadding} />
          </Stack>
        )}
        {screenWidth < 1200 && (
          <Stack>
            <RegisterForm setIsPadding={setIsPadding} />
          </Stack>
        )}
      </Container>
      <Stack
        mt={isMobile ? 8.5 : 12}
        pb={isPadding ? 50 : 20}
        sx={{ backgroundColor: '#F0F0F0', width: '100%' }}
      >
        <Container maxWidth="xl">
          <Stack width={isMobile ? 'unset' : 500} pt={10} pl={3} pr={3} pb={5} sx={{ color: 'black' }}>
            <Typography textAlign="justify" variant="body2">
              Join us for an engaging and nostalgic gathering as we celebrate the Nostalmea Alumni
              Meet, bringing together graduates from MEA to reconnect, share stories, and strengthen
              our community.
            </Typography>
            <Typography variant="body2">
              Date:
              <Typography component="span" variant="body2" pl={1}>
                December 1, 2024
              </Typography>
            </Typography>
            <Typography variant="body2">
              Location:
              <Typography component="span" variant="body2" pl={1}>
                Salama Community Hall, Dubai Airport Free Zone
              </Typography>
            </Typography>
            <Typography variant="body2" py={3}>
              {' '}
              What to expect?
            </Typography>
            <Typography textAlign="justify" variant="body2">
              This event promises an enriching experience as we reflect on our journeys and build
              connections for the future. Whether you’re a recent graduate or a seasoned alumnus,
              this meet will offer valuable insights and memorable moments.
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} pl={3} sx={{ color: '#1a1a1a' }}>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              SHARE
            </Typography>
            <Box sx={{ width: '30px', height: '1px', bgcolor: '#1a1a1a' }} />
            <a
              href="https://www.instagram.com/uaemeca"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <InstagramIcon fontSize="small" />
            </a>
          </Stack>
        </Container>
      </Stack>
      <Stack
        py={1}
        sx={{
          backgroundColor: '#E6E6E6',
        }}
      >
        <CardMedia component="img" src={OsperbLogo} alt="" sx={{ width: 100, margin: 'auto' }} />
      </Stack>
    </Stack>
  );
}
