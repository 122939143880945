import { useState, useEffect } from 'react';

export const useScreenWidth = () => {
  const [screenWidth, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add the event listener to track window resize
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const isMobile = screenWidth <= 600 || false;

  return { screenWidth, isMobile };
};
