// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  LMS: '/academy',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    privacy_policy: '/privacy-policy',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
    register: `/register`,
  },
  // AUTH
  auth: {
    login: `/login`,
    logout: `${ROOTS.AUTH}/logout`,
    create_password: (type, token) => `${ROOTS.AUTH}/create-password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `${ROOTS.AUTH}/password/reset/:${type}/:${token}`,
    forgot_password: `${ROOTS.AUTH}/forgot-password`,
    password_verification: (type, token) => `${ROOTS.AUTH}/invitation/:${type}/:${token}`,
    register_success: `/register-success`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    registrations: `${ROOTS.DASHBOARD}/registrations`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  partner: 'Partner',
  school: 'School',
  academy: 'Student',
};
