import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, post } from './http';
import { activeSnack } from '../store/common';

export const registerMember = createAsyncThunk(
  'registration/member/create',
  async ({ state, dispatch, navigate }) => {

    console.log('form datas',state);

    try {
      const response = await post(`/member/public/new`, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Registered successfully' }));
        if (navigate) {
          navigate(paths.auth.register_success);
        }
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const memberRgistrations = createAsyncThunk(
  'registration/list',
  async ({
    page,
    search,
    limit,
    sortOrder,
    sortfield,
    country,
    state,
    dispatch,
    passoutYearFrom,
    passoutYearTo,
  }) => {
    try {
      const response = await get(
        `/member/admin/all?page=${page ?? 1}&search=${search ?? ''}&limit=${
          limit ?? 5
        }&sort_order=${sortOrder || ''}&from=${passoutYearFrom || ''}&to=${passoutYearTo || ''
        }&sort_field=${sortfield || ''}&country=${country || ''}&state=${state || ''}`
      );
      if (response?._id) {
        return response;
      }
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
