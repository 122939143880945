import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import userReducer from './user';
import stateReducer from './state';
import commonReducer from './common';
import CountryReducer from './country';
import DistrictReducer from './district';
import settingsReducer from './settings';
import dashboardReducer from './dashboard';
import memberRegisterReducer from './memberRegistration';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    district: DistrictReducer,
    settings: settingsReducer,
    state: stateReducer,
    user: userReducer,
    memberRegister: memberRegisterReducer,
  },
});
