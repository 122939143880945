import PropTypes from 'prop-types';

import {
  Box,
  Stack,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function RegistrationDetailsModal({ open, onClose, rowData }) {
  const {
    full_name,
    phone,
    country_code,
    whatsapp_country_code,
    whatsapp_number,
    country,
    state,
    pass_out_year,
    email,
    currently_working,
    job_details,
    family_members_count,
    family_members,
  } = rowData;

  console.log('row data', job_details);

  const countryName = country?.name || 'N/A';
  const stateName = state?.name || 'N/A';

  const listItemStyle = { fontSize: '0.875rem', fontWeight: 400 };
  const iconSize = 20;

  // Formatting phone and WhatsApp numbers
  const formattedCountryCode = country_code?.startsWith('+') ? country_code : `+${country_code}`;
  const formattedWhatsappCountryCode = whatsapp_country_code?.startsWith('+')
    ? whatsapp_country_code
    : `+${whatsapp_country_code}`;
  const formattedPhone = phone ? `${formattedCountryCode} ${phone}` : 'N/A';
  const formattedWhatsappNumber = whatsapp_number
    ? `${formattedWhatsappCountryCode} ${whatsapp_number}`
    : 'N/A';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Registration Details</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          {/* Full Name */}
          <Typography variant="body2" sx={listItemStyle}>
            <Typography component="span" variant="subtitle2">
              Full Name:
            </Typography>{' '}
            {full_name}
          </Typography>

          {/* Email */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon="fluent:mail-24-filled" width={iconSize} />
            <Typography variant="body2">{email}</Typography>
          </Stack>

          {/* Phone Number */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon="material-symbols:phone-enabled" width={iconSize} />
            <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
              {formattedPhone}
            </Typography>
          </Stack>

          {/* WhatsApp Number */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon="mdi:whatsapp" width={iconSize} />
            <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
              {formattedWhatsappNumber}
            </Typography>
          </Stack>

          {/* Location */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon="mingcute:location-fill" width={iconSize} />
            <Typography variant="subtitle1">
              <Typography component="span" variant="body2">
                Live at
              </Typography>{' '}
              {`${stateName}, ${countryName}`}
            </Typography>
          </Stack>

          {/* Pass Out Year */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon="mdi:calendar-check" width={iconSize} />
            <Typography variant="subtitle1">
              <Typography component="span" variant="body2">
                Year of passout at
              </Typography>{' '}
              {pass_out_year || 'N/A'}
            </Typography>
          </Stack>

          {/* Job Details */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify icon="material-symbols:work-outline" width={iconSize} />
            <Typography variant="body2">{currently_working ? 'Working' : 'Not Working'}</Typography>
          </Stack>

          {/* Job Details Section */}
          {currently_working && job_details && (
            <Stack spacing={2} sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Job Details:
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                  gap: 2,
                }}
              >
                {/* Job Position */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Iconify icon="mdi:account-tie-outline" width={20} />
                  <Typography variant="body2" sx={{  fontWeight: 500 }}>
                    Position:
                  </Typography>
                  <Typography variant="body2"  >
                    {job_details?.job_position || 'N/A'}
                  </Typography>
                </Stack>
                {/* Job Industry */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Iconify icon="mdi:domain" width={20} />
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    Industry:
                  </Typography>
                  <Typography variant="body2" >
                    {job_details?.job_industry || 'N/A'}
                  </Typography>
                </Stack>

                {/* Total Experience */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Iconify icon="mdi:briefcase-outline" width={20} />
                  <Typography variant="body2" sx={{  fontWeight: 500 }}>
                    Experience:
                  </Typography>
                  <Typography variant="body2" >
                    {job_details?.job_experience || 'No experience'}
                  </Typography>
                </Stack>
                {/* Job Country */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Iconify icon="mdi:earth" width={20} />
                  <Typography variant="body2" sx={{  fontWeight: 500 }}>
                    Country:
                  </Typography>
                  <Typography variant="body2" >
                    {job_details?.office_country?.name || 'N/A'}
                  </Typography>
                </Stack>

                {/* Job Place */}
                <Stack direction="row" spacing={1} alignItems="center">
                  <Iconify icon="mdi:office-building-outline" width={20} />
                  <Typography variant="body2" sx={{  fontWeight: 500 }}>
                    Place:
                  </Typography>
                  <Typography variant="body2" >
                    {job_details?.office_place || 'N/A'}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          )}

          {/* Family Details */}
          {family_members_count > 0 && (
            <Typography variant="body2" mt={1} sx={listItemStyle}>
              <Typography component="span" sx={{ fontWeight: 500 }}>
                Family Members Count:
              </Typography>{' '}
              {family_members_count}
            </Typography>
          )}

          {family_members && family_members.length > 0 && (
            <Stack spacing={2} sx={{ paddingLeft: 1, paddingTop: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                Family Members:
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                  gap: 2,
                }}
              >
                {family_members.map((member, index) => (
                  <Box
                    key={index}
                    sx={{
                      padding: 2,
                      border: '1px solid #e0e0e0',
                      borderRadius: 1,
                      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.15)',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {member.name || 'N/A'}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary', fontSize: '0.80rem' }}
                    >
                      Age: {member.age || 'N/A'}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

RegistrationDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rowData: PropTypes.object.isRequired,
};
