import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
// import CardHeader from '@mui/material/CardHeader';
// import { Card, Stack, Button, Typography } from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

// import { CurrencySwitch } from 'src/hooks/use-currency';

// import eye from 'src/assets/other/eye.png';
// import cart from 'src/assets/other/cart.png';
// import graph from 'src/assets/other/graph.png';
// import person from 'src/assets/other/person.png';
// import product from 'src/assets/other/product.png';

// import SvgColor from 'src/components/svg-color';

// import ChartRadialBar from './chart-radial-bar';
// ----------------------------------------------------------------------

const SPACING = 3;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  // const currency = CurrencySwitch();

  // const { dashboardData } = useSelector((state) => ({
  //   dashboardData: state.dashboard.dashboardData,
  //   loading: state.dashboard.loading,
  // }));

  // console.log(dashboardData);
  // const icon = (name, IconColor) => (
  //   <SvgColor
  //     src={`/assets/icons/navbar/${name}.svg`}
  //     sx={{ width: 1, height: 1, color: IconColor }}
  //   />
  // );
  // console.log(currency);
  // const Guest = dashboardData?.totalGuestCount || 0;

  // const Seller = dashboardData?.totalSellersCount || 0;

  return (
    <Grid container spacing={SPACING} disableEqualOverflow marginBottom="1rem">
        {/* <Grid item xs={12} md={4} spacing={SPACING}>
          <Link to={paths.dashboard.marketPrice}>
            <Card
              className=""
              sx={{
                backgroundColor: '#333333',
                flexDirection: 'column',
                position: 'relative',
                paddingLeft: '2rem',
                paddingTop: '10.2rem',
              }}
            >
              <Stack
                sx={{ position: 'absolute', top: '71px', marginLeft: '5px', marginRight: '5px' }}
              >
                <Typography className="market-text-dashboard">Market Analytics</Typography>
                <Typography className="market-para-dashboard">
                  Price Management: Adjust prices and make predictions here.
                </Typography>
                <Button className="market-btn-dashboard">Adjust Price & Predict</Button>
              </Stack>
              <Stack sx={{ alignItems: 'flex-end' }}>
                <img src={graph} alt="users" className="color-card-img card-1-img" />
              </Stack>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} spacing={SPACING}>
          <Link to={paths.dashboard.products} style={{ textDecoration: 'none' }}>
            <Card
              className="dashboard-cards-new colored-dashboard-card"
              sx={{
                backgroundImage: `url(${product})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            > */}
              {/* <Stack className="dashboard-card2" sx={{ padding: '1.5rem' }}>
                <Typography className="product-text-dashboard ">PRODUCT</Typography>
                <Typography className="product-count-dashboard ">
                  {dashboardData?.totalPendingProductsCount || 0}
                  <span className="red-dot " />
                </Typography>
                <Typography className="product-para-dashboard ">
                  Products are in the queue, pending approval
                </Typography>
              </Stack>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} spacing={SPACING}>
          <Link to={paths.dashboard.products} style={{ textDecoration: 'none' }}>
            <Card className="dashboard-cards-new">
              <Typography className="card-title">Total Products</Typography>
              <Typography className="card-count">
                {dashboardData?.totalProductsCount || 0}
              </Typography>
            </Card>
          </Link>
          <Link to={paths.dashboard.products} style={{ textDecoration: 'none' }}>
            <Card className="dashboard-cards-new">
              <Typography className="card-count">
                {dashboardData?.thisMonthProductsCount || 0}
              </Typography>
              <Typography
                className="card-title"
                sx={{ color: '#a5a2a2', display: 'flex', flexDirection: 'row' }}
              >
                <div className="calender-div"> */}
                  {/* <img src={calender} alt="calender" /> */}
                  {/* {icon('ic_calendar', 'black')}
                </div>{' '}
                Products this month
              </Typography>
            </Card>
          </Link>
        </Grid>
      </Grid> */}
{/* 
      <Grid container spacing={SPACING} disableEqualOverflow marginBottom="2rem">
        <Grid item xs={12} md={4} spacing={SPACING}>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card className="dashboard-cards-new">
              <Typography className="card-title">Total Users</Typography>
              <Typography className="card-count">{dashboardData?.totalUsersCount || 0}</Typography>
            </Card>
          </Link>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card className="dashboard-cards-new">
              <Typography className="card-title">Sellers</Typography>
              <Typography className="card-count">
                {dashboardData?.totalSellersCount || 0}
              </Typography>
            </Card>
          </Link>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card className="dashboard-cards-new">
              <Typography className="card-title">Guest</Typography>
              <Typography className="card-count">{dashboardData?.totalGuestCount || 0}</Typography>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} spacing={SPACING}>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card>
              <CardHeader title="Total Users" sx={{ mb: 5 }} />
              <ChartRadialBar
                series={[Guest, Seller]}
                totalUsersCount={dashboardData?.totalUsersCount || 0}
              />
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} spacing={SPACING}>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card
              className="dashboard-cards-new colored-dashboard-card"
              sx={{
                backgroundColor: '#333333',
              }}
            >
              <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
                <Typography className="color-card-count">
                  {dashboardData?.thisMonthUsersCount || 0}
                </Typography>
                <Typography className="color-card-title">Total users this month</Typography>
              </Stack>
              <img src={person} alt="users" className="color-card-img" />
            </Card>
          </Link>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card
              className="dashboard-cards-new colored-dashboard-card"
              sx={{
                backgroundColor: '#007867',
              }}
            >
              <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
                <Typography className="color-card-count">
                  {dashboardData?.thisMonthSellersCount || 0}
                </Typography>
                <Typography className="color-card-title">Sellers this month</Typography>
              </Stack>
              <img src={cart} alt="sellers" className="color-card-img" />
            </Card>
          </Link>
          <Link to={paths.dashboard.users} style={{ textDecoration: 'none' }}>
            <Card
              className="dashboard-cards-new colored-dashboard-card"
              sx={{
                backgroundColor: '#FFAB00',
              }}
            >
              <Stack sx={{ paddingLeft: '1.5rem', marginY: 'auto' }}>
                <Typography className="color-card-count">
                  {dashboardData?.thisMonthGuestsCount}
                </Typography>
                <Typography className="color-card-title" sx={{ color: '#212B36' }}>
                  Guests this month
                </Typography>
              </Stack>
              <img
                src={eye}
                alt="view"
                className="color-card-img"
                style={{ marginBottom: '1rem', marginTop: '1rem' }}
              />
            </Card>
          </Link>
        </Grid> */}
      </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
