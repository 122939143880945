import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableBody, TableCell, CircularProgress } from '@mui/material';

// import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { memberRgistrations } from 'src/server/api/memberRegistration';

import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from 'src/components/table';

import RegistrationsTableRow from 'src/sections/admin/registrations/Registrations-Table-Row';
import RegistrationTableToolbar from 'src/sections/admin/registrations/Registration-Table-Toolbar';

export default function Registrations() {
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const table = useTable({ defaultOrderBy: 'createdAt',defaultDense:true });
  const confirm = useBoolean();

  const { countryList, stateList, memberRegistrations, loading } = useSelector((state) => ({
    loading: state.memberRegister.loading,
    countryList: state.country.country,
    stateList: state.state.states,
    memberRegistrations: state.memberRegister.allMemberRegistrations,
  }));

  const identifier = 'admin-registrations';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const totalPage = memberRegistrations?.total;

  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [yearRange, setYearRange] = useState({ from: '', to: '' });

  // Define table headers
  const TABLE_HEAD = [
    { id: 'full_name', label: 'Name' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'country', label: 'Country' },
    { id: 'state', label: 'State' },
    { id: 'pass_out_year', label: 'PassoutYear' },
    { id: '', label: '' },
  ];

  const setPage = () => {
    table.setPage(0);
  };

  // Fetch Member Registrations
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText,
      limit: storedRowsPerPage || table.rowsPerPage,
      sortfield: 'pass_out_year',
      sortOrder,
      country: selectedCountry?.value || null,
      state: selectedState?.value || null,
      passoutYearFrom: yearRange.from || '',
      passoutYearTo: yearRange.to || '',
    };

    // Dispatch API call with updated filters
    dispatch(memberRgistrations(credentials));
  }, [
    dispatch,
    searchText,
    selectedCountry,
    selectedState,
    sortOrder,
    yearRange,
    storedRowsPerPage,
    table.rowsPerPage,
    table.page,
  ]);

  // Update table data when member registrations change
  useEffect(() => {
    if (memberRegistrations?.members) {
      setTableData(memberRegistrations.members);
    }
  }, [memberRegistrations]);

  const handleYearRangeChange = (range) => {
    setYearRange(range);
    setPage();
  };

  return (
    <>
      <MetaHelmet title="Registrations" />
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Registrations"
          links={[{ name: 'Dashboard' }, { name: 'Registrations' }]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <RegistrationTableToolbar
            searchValue={searchText}
            onSearch={setSearchText}
            setPage={setPage}
            filters={category}
            onCategory={setCategory}
            countryList={countryList}
            stateList={stateList}
            setSelectedState={setSelectedState}
            setSelectedCountry={setSelectedCountry}
            selectedState={selectedState}
            selectedCountry={selectedCountry}
            onSortChange={setSortOrder}
            sortOrder={sortOrder}
            onYearRangeChange={handleYearRangeChange}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                {loading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="success" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {tableData.map((row) => (
                      <RegistrationsTableRow
                        key={row._id}
                        row={row}
                        selected={table.selected.includes(row._id)}
                        onSelectRow={() => table.onSelectRow(row._id)}
                      />
                    ))}
                  </TableBody>
                )}
                <TableNoData notFound={!tableData.length} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure you want to delete ${table.selected.length} items?`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // Implement delete logic here
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
