import { useState } from 'react';
import PropTypes from 'prop-types';

import { TableRow, TableCell, Typography } from '@mui/material';

import Label from 'src/components/label';

import RegistrationDetailsModal from './RegistrationDetailsModal';

// ----------------------------------------------------------------------

export default function RegistrationTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
  loading,
  colSpan,
  handleEditClick,
  index,
  page,
  rowsPerPage,
}) {
  const { country, state, full_name, phone, country_code, pass_out_year, email } = row;

  // const { enqueueSnackbar } = useSnackbar();

  const formattedCountryCode = country_code?.startsWith('+') ? country_code : `+${country_code}`;

  const formattedPhone = phone ? `${formattedCountryCode} ${phone}` : 'N/A';

  // State for opening the modal
  const [openModal, setOpenModal] = useState(false);

  // Modal handlers
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <TableRow hover selected={selected}>
        {/* Full Name */}
        <TableCell>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {full_name}
          </Typography>
        </TableCell>

        {/* Country Code + Phone */}
        <TableCell>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {formattedPhone}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {email}
          </Typography>
        </TableCell>

        {/* Country and State Names */}
        <TableCell>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {country?.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
            {state?.name}
          </Typography>
        </TableCell>

        {/* Pass Out Year */}
        <TableCell>
          <Typography variant="subtitle2">{pass_out_year}</Typography>
        </TableCell>

        {/* View Button */}
        <TableCell>
          <Label
            variant="soft"
            color="primary"
            sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            onClick={handleOpenModal}
          >
            view
          </Label>
        </TableCell>
      </TableRow>

      {/* Modal for Viewing Details */}
      <RegistrationDetailsModal open={openModal} onClose={handleCloseModal} rowData={row} />
    </>
  );
}

RegistrationTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
  handleEditClick: PropTypes.func,
  index: PropTypes.any,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
};
