import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import SettingsPage from 'src/pages/Admin/settings';
import RegisterPage from 'src/pages/Common/Auth/Register';
import LoginAdmin from 'src/assets/other/bannerbg_register.svg';
import Registrations from 'src/pages/Admin/settings/registrations/registrations';

import { SplashScreen } from 'src/components/loading-screen';

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));
const RegisterSuccess = lazy(() => import('src/pages/Common/Auth/RegisterSuccess'));
const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));
const RegisterLayout = lazy(() => import('src/layouts/auth/newRegisterLayout'));


const CompactLayout = lazy(() => import('src/layouts/compact'));
const ProfileView = lazy(() => import('src/pages/Common/Auth/profile/index'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      // { path: 'privacy-policy', element: <PrivacyPolicy /> },
    ],
  },
];

const authRoutes = [
  {
    element: (
      <RegisterLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </RegisterLayout>
    ),

    children: [
      {
        path: 'register',
        element: <RegisterPage />,
      },
      {
        path: 'register-success',
        element: <RegisterSuccess />,
      },
      {
        path: 'login',
        element: (
          <AuthLayout image={LoginAdmin} title="Authenticate as an administrator" userRole="admin">
            <LoginAdminPage role={AuthRoles.admin} />
          </AuthLayout>
        ),
        // children: [
        //   {
        //     element: (
        //       <AuthLayout
        //         image={LoginAdmin}
        //         title="Authenticate as an administrator"
        //         userRole="admin"
        //       >
        //         <LoginAdminPage role={AuthRoles.admin} />
        //       </AuthLayout>
        //     ),
        //   },
        // ],
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot-password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create-password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  {
    path: 'settings',
    element: <SettingsPage />,
  },
  {
    path: 'profile',
    element: <ProfileView />,
  },
  {
    path: 'registrations',
    element: <Registrations />,
  },
];

export { authRoutes, adminRoutes, publicRoutes };
