import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Grid,
  Stack,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  Autocomplete,
  InputAdornment,
} from '@mui/material';

import { getAllState } from 'src/server/api/state';
import { getAllCountry } from 'src/server/api/country';

import Iconify from 'src/components/iconify';

export default function RegistrationTableToolbar({
  searchValue,
  onSearch,
  onFilters,
  filters,
  onCategory,
  countryList,
  stateList,
  setSelectedState,
  setSelectedCountry,
  selectedState,
  selectedCountry,
  onSortChange,
  sortOrder,
  setPage,
  onYearRangeChange,
}) {
  const [countrySearchText, setCountrySearchText] = useState('');
  const [searchValueState, setSearchValueSate] = useState('');
  const [sort, setSort] = useState('ASCENDING');
  const [passoutYearFrom, setPassoutYearFrom] = useState('');
  const [passoutYearTo, setPassoutYearTo] = useState('');

  const dispatch = useDispatch();

  // Fetch countries
  useEffect(() => {
    dispatch(getAllCountry({ page: 1, search: countrySearchText }));
  }, [dispatch, countrySearchText]);

  // Fetch states based on selected country
  useEffect(() => {
    if (selectedCountry) {
      dispatch(getAllState({ page: 1, country: selectedCountry?.value, search: searchValueState }));
    }
  }, [dispatch, selectedCountry, searchValueState]);

  // Country and state options
  const optionCountry = (countryList?.countries || []).map((results) => ({
    label: results?.name,
    value: results?._id,
  }));

  const optionState = (stateList?.states || []).map((results) => ({
    label: results?.name,
    value: results?._id,
  }));

  // Handlers for Country and State selection
  const handleAutocompleteChangeCountry = (newValue) => {
    setSelectedCountry(newValue);
    setPage();
  };
  const handleEntersCountry = (event) => {
    setCountrySearchText(event.target.value);
    setPage();
  };
  const handleAutocompleteChangeState = (_, newValue) => {
    setSelectedState(newValue);
    setPage();
  };
  const handleTextFieldChangeState = (event) => {
    setSearchValueSate(event.target.value);
    setPage();
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sort === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING';
    setSort(newSortOrder);
    onSortChange(newSortOrder);
  };

  const generateYears = (startYear, endYear) => {
    const years = [];
    // eslint-disable-next-line no-plusplus
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const startYear = 2006;
  const endYear = 2077;
  const currentYear = new Date().getFullYear();
  const years = generateYears(startYear, endYear);

  const handleYearFromChange = (event) => {
    const selectedYear = event.target.value;
    setPassoutYearFrom(selectedYear === 'none' ? '' : selectedYear);

    if (passoutYearTo && selectedYear > passoutYearTo) {
      setPassoutYearTo('');
    }

    onYearRangeChange({ from: selectedYear, to: passoutYearTo });
  };

  const handleYearToChange = (event) => {
    const selectedYear = event.target.value;
    setPassoutYearTo(selectedYear);

    // Update the year range in the parent component
    onYearRangeChange({ from: passoutYearFrom, to: selectedYear });
  };
  const searchChange = (value) => {
    onSearch(value);
    setPage();
  };

  return (
    <Grid container spacing={2} sx={{ p: 2.5 }}>
      {/* First Row: Search and Country/State Selector */}
      <Grid item xs={12} sm={6} md={6}>
        <TextField
          fullWidth
          value={filters.name}
          onChange={(e) => searchChange(e.target.value)}
          placeholder="Search"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* Country and State Selector */}
      <Grid item xs={12} sm={6} md={6} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={optionCountry}
            autoHighlight
            size="small"
            fullWidth
            getOptionLabel={(option) => option.label}
            onChange={(e, v) => handleAutocompleteChangeCountry(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                onChange={handleEntersCountry}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </Grid>

        {selectedCountry && (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={optionState}
              autoHighlight
              size="small"
              fullWidth
              onChange={handleAutocompleteChangeState}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State/Province"
                  onChange={handleTextFieldChangeState}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        container
        spacing={2}
        sx={{ display: 'flex', mt: '3px', ml: '2px' }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2.5}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {/* Passout Year From */}
          <Select
            size="small"
            fullWidth
            value={passoutYearFrom}
            onChange={handleYearFromChange}
            displayEmpty
          >
            <MenuItem value="">
              <Typography variant="body2" color="textSecondary">
                Passout Year From
              </Typography>
            </MenuItem>
            <MenuItem value="none">
              <Typography variant="body2" color="textSecondary">
                None
              </Typography>
            </MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year} disabled={year > currentYear}>
                {year}
              </MenuItem>
            ))}
          </Select>

          {/* Passout Year To */}
          <Select
            size="small"
            fullWidth
            value={passoutYearTo}
            onChange={handleYearToChange}
            displayEmpty
          >
            <MenuItem value="">
              <Typography variant="body2" color="textSecondary">
                Passout Year To
              </Typography>
            </MenuItem>
            <MenuItem value="none">
              <Typography variant="body2" color="textSecondary">
                None
              </Typography>
            </MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year} disabled={year > currentYear}>
                {year}
              </MenuItem>
            ))}
          </Select>

          {/* Passout Year Text and Sort Option */}
          <Stack width="100%" direction="row">
            {/* <Typography variant="body2" sx={{ color: 'text.primary'}}>
              Passout Year
            </Typography> */}
            <IconButton
              onClick={handleSortOrderChange}
              sx={{ color: 'inherit', borderRadius: 1 }}
              title={`Sort Passout Year ${sort === 'ASCENDING' ? 'Descending' : 'Ascending'}`}
            >
              <Typography variant="body2" pr={0.5} sx={{ color: 'text.primary' }}>
                Passout Year
              </Typography>
              {sort === 'ASCENDING' ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              )}
            </IconButton>
          </Stack>
        </Stack>
      </Grid>

      {/* Line Under Passout Year Sort Option */}
      {/* <Grid item xs={12}>
        <hr style={{ border: '1px solid #ddd', marginTop: '8px', marginBottom: '8px' }} />
      </Grid> */}
    </Grid>
  );
}

// Define PropTypes
RegistrationTableToolbar.propTypes = {
  searchValue: PropTypes.string,
  onFilters: PropTypes.func,
  onSearch: PropTypes.func,
  filters: PropTypes.string,
  onCategory: PropTypes.func,
  countryList: PropTypes.any,
  stateList: PropTypes.any,
  setSelectedState: PropTypes.func,
  setSelectedCountry: PropTypes.func,
  selectedState: PropTypes.object,
  selectedCountry: PropTypes.object,
  onSortChange: PropTypes.func.isRequired,
  sortOrder: PropTypes.string,
  setPage: PropTypes.func,
  onYearRangeChange: PropTypes.func,
};
