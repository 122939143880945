import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';

import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Card, Select, Checkbox, MenuItem, InputLabel, FormControl } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';
import { useScreenWidth } from 'src/hooks/useScreenWidth';

import { getAllState } from 'src/server/api/state';
import { getAllCountry } from 'src/server/api/country';
import { registerMember } from 'src/server/api/memberRegistration';

import Scrollbar from 'src/components/scrollbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFPhoneInput } from 'src/components/hook-form/rhf-phone-input';
import OspSelectAutocomplete from 'src/components/hook-form/rhf-OspSelectAutocomplete';

export default function RegisterForm({ setIsPadding }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { screenWidth } = useScreenWidth();
  const { countryList, stateList, loading } = useSelector((state) => ({
    countryList: state.country.country,
    stateList: state.state.states,
    loading: state.memberRegister.loading,
  }));
  const isMobile = useResponsive('down', 'sm');
  const isWeb = useResponsive('up', 'sm');

  // const [familyCount, setFamilyCount] = useState(1);
  const [countrySearchText, setCountrySearchText] = useState('');
  const [searchValueState, setSearchValueState] = useState('');
  const [searchValueofficeCountry, setCountryOfficeText] = useState('');

  const [selectedCode, setSelectedCode] = useState('91');
  const [selectedWhatappCode, setSelectedWhatsappCode] = useState('91');
  const [selectedYear, setSelectedYear] = useState('');

  const generateYears = (startYear, endYear) => {
    const years = [];
    // eslint-disable-next-line no-plusplus
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const startYear = 2006;
  const endYear = 2077;
  const currentYear = new Date().getFullYear(); // Get the current year
  const years = generateYears(startYear, endYear);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllCountry({ page: 1, search: countrySearchText }));
  }, [dispatch, countrySearchText]);

  useEffect(() => {
    dispatch(getAllCountry({ page: 1, search: searchValueofficeCountry }));
  }, [dispatch, searchValueofficeCountry]);

  // const currentYear = new Date().getFullYear();

  const RegisterSchema = Yup.object().shape({
    full_name: Yup.string().required('Full name is required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    whatsapp_number: Yup.string(),
    pass_out_year: Yup.number().required('This field is required'),
    country: Yup.object(),
    state: Yup.object(),
    job_details: Yup.object().shape({
      job_position: Yup.string(),
      job_experience: Yup.number(),
      job_industry: Yup.string(),
      office_place: Yup.string(),
      office_country: Yup.object(),
    }),
    currently_working: Yup.string(),
    family_member_count: Yup.number(),
    family_members: Yup.array(),
    event_attendance_status: Yup.string(),
  });

  const defaultValues = {
    full_name: '',
    phone: '',
    whatsapp_number: '',
    email: '',
    pass_out_year: 0,
    state: {},
    country: {},
    job_details: {
      job_position: '',
      job_experience: 0,
      job_industry: '',
      office_place: '',
      office_country: {},
    },
    family_member_count: 1,
    family_members: [],
    event_attendance_status: '',
    currently_working: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
  } = methods;

  const currentlyWorking = watch('currently_working');
  const attendance = watch('event_attendance_status');

  const onSubmit = async (data) => {
    const { country, state, phone, whatsapp_number, job_details, ...other } = data;
    const formattedData = {
      ...other,
      state: state?.value || null,
      country: country?.value || null,
      pass_out_year: selectedYear,
      phone: parsePhoneNumberFromString(phone)?.nationalNumber,
      whatsapp_number: parsePhoneNumberFromString(whatsapp_number)?.nationalNumber || '',
      country_code: selectedCode,
      whatsapp_country_code: whatsapp_number ? selectedWhatappCode : '',
      currently_working: data?.currently_working === 'Yes',
      family_members_count: data?.event_attendance_status === 'FAMILY' ? Number(familyCount) : 0,
      family_members: data?.event_attendance_status === 'FAMILY' ? data?.family_members : [],
    };

    if (data?.currently_working === 'Yes') {
      formattedData.job_details = {
        ...job_details,
        office_country: job_details?.office_country?.value || null,
      };
    } else {
      formattedData.job_details = {};
    }
    const credentials = {
      state: formattedData,
      dispatch,
      navigate,
    };
    dispatch(registerMember(credentials));
  };

  const optionCountry = [
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const handleTextFieldChangeCountry = (value) => {
    setCountrySearchText(value);
    if (value) {
      setCountryOfficeText('');
    }
  };
  const optionState = [
    ...(stateList?.states || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const handleTextFieldChangeState = (value) => {
    setSearchValueState(value);
  };

  const selectedCountry = watch('country');
  const familyCount = watch('family_members_count');

  useEffect(() => {
    if (selectedCountry) {
      dispatch(getAllState({ page: 1, country: selectedCountry?.value, search: searchValueState }));
    }
  }, [dispatch, selectedCountry, searchValueState]);

  const optionCountryOffice = [
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const handleTextFieldOfficeChangeCountry = (value) => {
    setCountryOfficeText(value);
    if (value) {
      setCountrySearchText('');
    }
  };

  const [sameAsPhone, setSameAsPhone] = useState(false);

  const phone = watch('phone');

  useEffect(() => {
    if (sameAsPhone) {
      methods.setValue('whatsapp_number', phone);
    }
  }, [phone, sameAsPhone, methods]);

  useEffect(() => {
    if (currentlyWorking === 'Yes' && attendance === 'FAMILY') {
      setIsPadding(true);
    } else {
      setIsPadding(false);
    }
  }, [attendance, currentlyWorking, setIsPadding]);

  const handleCheckboxChange = (event) => {
    setSameAsPhone(event.target.checked);
    if (event.target.checked) {
      methods.setValue('whatsapp_number', phone);
      setSelectedWhatsappCode(selectedCode);
    } else {
      methods.setValue('whatsapp_number', '');
      setSelectedWhatsappCode('91');
    }
  };

  const handlePhoneInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedCode(country_code);
  };

  const handleWhatappNumberInputChange = (value, inputName) => {
    const country_code = getCountryCallingCode(value);
    setSelectedWhatsappCode(country_code);
  };

  const handleChangeCountry = () => {
    methods.setValue('state', {});
  };

  return (
    <Card
      variant="outlined"
      sx={{
        p: 3,
        pb: 5,
        width: screenWidth > 600 ? 500 : 'unset',
        borderRadius: 0,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        margin: screenWidth > 600 && screenWidth < 1200 ? 'auto' : 'unset',
        // height: 1000
      }}
    >
      <Typography variant="h4" pb={3}>
        Event Registration
      </Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={2} mb={2}>
          <RHFTextField name="full_name" label="Full Name" required sx={{ flex: 1 }} />
          <RHFTextField name="email" label="Email" required sx={{ flex: 1 }} />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={2} mb={2}>
          <RHFPhoneInput
            name="phone"
            placeholder="Phone Number *"
            setSelectedCode={(value) => handlePhoneInputChange(value, 'phone')}
            required
            sx={{ width: { xs: '100%', sm: '50%' } }}
          />
          {sameAsPhone && (
            <Stack
              direction="row"
              alignItems="center"
              width="50%"
              pl={1.5}
              sx={{ border: '1px solid #ebebeb', borderRadius: 1 }}
            >
              <Typography color="#4b4848">{selectedWhatappCode}</Typography>
              <Box sx={{ height: 30, width: '1px', backgroundColor: '#ebebeb', ml: 2, mr: 1 }} />
              <Typography color="#4b4848">
                {parsePhoneNumberFromString(phone)?.nationalNumber}
              </Typography>
            </Stack>
          )}
          {!sameAsPhone && (
            <RHFPhoneInput
              name="whatsapp_number"
              placeholder=" WhatsApp Number"
              setSelectedWhatsappCode={(value) =>
                handleWhatappNumberInputChange(value, 'whatsapp_number')
              }
              sx={{ width: { xs: '100%', sm: '50%' } }}
            />
          )}
          {isMobile && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                color: 'black',
                mt: -2,
              }}
            >
              <Checkbox name="sameAsPhone" checked={sameAsPhone} onChange={handleCheckboxChange} />
              <Typography color="#212B36" variant="body2">
                Whatsapp same as phone
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} position="relative" mb={2}>
          {/* <RHFTextField
            name="pass_out_year"
            label="MEA Passout Year"
            required
            type="number"
            sx={{ width: '50%' }}
          /> */}
          <FormControl
            required
            variant="outlined"
            sx={{ width: { xs: '100%', sm: '50%', lg: '50%' } }}
          >
            <InputLabel id="mea-passout-year-label">MEA Passout Year</InputLabel>
            <Select
              labelId="mea-passout-year-label"
              value={selectedYear}
              onChange={handleChange}
              label="MEA Passout Year"
            >
              {years.map((year) => (
                <MenuItem key={year} value={year} disabled={year > currentYear}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isWeb && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: -10,
                right: 15,
              }}
            >
              <Checkbox name="sameAsPhone" checked={sameAsPhone} onChange={handleCheckboxChange} />
              <Typography color="#212B36" variant="body2">
                Whatsapp same as phone
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={2} mb={2}>
          <OspSelectAutocomplete
            name="country"
            control={control}
            options={optionCountry}
            label="Country"
            handleTextFieldChange={handleTextFieldChangeCountry}
            onChange={handleChangeCountry}
            size="medium"
            sx={{ width: { xs: '100%', lg: '50%' } }}
          />
          {selectedCountry?.value && (
            <OspSelectAutocomplete
              name="state"
              control={control}
              options={optionState}
              label="State/Emirates/Province"
              handleTextFieldChange={handleTextFieldChangeState}
              size="medium"
              sx={{ flex: 1 }}
            />
          )}
        </Stack>
        <Stack mt={4}>
          <Typography variant="body2" pb={1}>
            Are you currently working?
          </Typography>
          <Controller
            name="currently_working"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field} sx={{ ml: 1.5 }}>
                <FormControlLabel
                  value="Yes"
                  control={<Radio className={currentlyWorking === 'Yes' ? 'custom-radio' : ''} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio className={currentlyWorking === 'No' ? 'custom-radio' : ''} />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
          {currentlyWorking === 'Yes' && (
            <>
              <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={2} my={2}>
                <RHFTextField name="job_details.job_industry" label="Job Industry" />
                <RHFTextField name="job_details.job_position" label="Job Position" />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={2} mb={2}>
                <RHFTextField
                  name="job_details.job_experience"
                  label="Years of Experience"
                  type="number"
                />
                <RHFTextField name="job_details.office_place" label="Office Place" />
              </Stack>
              <Stack width={{ xs: '100%', md: '50%' }} mb={2}>
                <OspSelectAutocomplete
                  name="job_details.office_country"
                  control={control}
                  options={optionCountryOffice}
                  label="Office Country"
                  handleTextFieldChange={handleTextFieldOfficeChangeCountry}
                />
              </Stack>
            </>
          )}
        </Stack>
        <Stack mt={2.5} mb={attendance === 'FAMILY' ? 1 : 2.5}>
          <Typography variant="body2" pb={1}>
            Will you be attending Nostalmea’24?
            <Typography component="span" color="red" pl={0.2}>
              *
            </Typography>
          </Typography>
          <Controller
            name="event_attendance_status"
            control={control}
            render={({ field }) => (
              <RadioGroup row={!isMobile} {...field} sx={{ ml: 1.5 }}>
                <FormControlLabel
                  value="FAMILY"
                  control={<Radio className={attendance === 'FAMILY' ? 'custom-radio' : ''} />}
                  label="Yes, with family"
                />
                <FormControlLabel
                  value="ALONE"
                  control={<Radio className={attendance === 'ALONE' ? 'custom-radio' : ''} />}
                  label="Yes, alone"
                />
                <FormControlLabel
                  value="NOT_ATTENDING"
                  control={
                    <Radio className={attendance === 'NOT_ATTENDING' ? 'custom-radio' : ''} />
                  }
                  label="Not attending"
                />
              </RadioGroup>
            )}
          />
          {attendance === 'FAMILY' && (
            <Stack mt={2}>
              <Typography variant="body2" pb={2}>
                How many family members will accompany you?
              </Typography>
              <RHFTextField
                name="family_members_count"
                label="Count"
                sx={{ width: '100px', mb: 2 }}
              />
              <Scrollbar sx={{ maxHeight: 200 }}>
                {Array.from({ length: familyCount }, (_, index) => (
                  <Stack direction="column" key={index} spacing={1} mb={2}>
                    <Typography variant="body2">{`Family Member ${index + 1}`}</Typography>
                    <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} spacing={1.5}>
                      <RHFTextField
                        name={`family_members[${index}].name`}
                        label="Name"
                        sx={{
                          width: { xs: '100%', sm: '70%' },
                        }}
                      />
                      <RHFTextField
                        name={`family_members[${index}].age`}
                        label="Age"
                        type="number"
                        sx={{
                          width: { xs: 150, sm: '30%' },
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </Scrollbar>
            </Stack>
          )}
        </Stack>
        <LoadingButton
          type="submit"
          variant="contained"
          color="info"
          sx={{ backgroundColor: '#146EF5', width: '150px', borderRadius: '4px', px: 11 }}
          size="large"
          disabled={!attendance}
          loading={loading || isSubmitting}
        >
          Register
        </LoadingButton>
      </FormProvider>
    </Card>
  );
}

RegisterForm.propTypes = {
  setIsPadding: PropTypes.any,
};
